import * as React from 'react';
import Masonry from 'react-masonry-component';
import "./ReviewSection.css";
import ReviewSectionInfoModal from './ReviewSectionGalleryInfoModal';
import ReviewSectionGalleryImage from './ReviewSectionGalleryImage';

const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

const ReviewSectionGallery = ({
  challengePackage,
  packageAccesses,
  submissions,
  selectedSubmissions,
  setSelectedSubmissionsHandler
}) => {
  const [submissionUnderInspection, setSubmissionUnderInspection] = React.useState(null);
  const getChildElements = () => {
    const childElements = submissions.map((submission) => {
      const isSelected = selectedSubmissions.find(
        (selSub) => {
          return selSub.id === submission.id;
        });
      return (
        <ReviewSectionGalleryImage
          key={submission.id}
          isSelected={isSelected}
          submission={submission}
          setSubmissionUnderInspectionHandler={setSubmissionUnderInspection}
          setSelectedSubmissionsHandler={setSelectedSubmissionsHandler}
        />
      );
    });

    return childElements;
  }

  return (
    <Masonry
      className={'my-gallery-class'} // default ''
      elementType={'div'} // default 'div'
      options={masonryOptions} // default {}
      disableImagesLoaded={false} // default false
      updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
      imagesLoadedOptions={imagesLoadedOptions} // default {}
    >
      {
        submissions?.length !== 0 ?
          getChildElements()
          : <h3>No Submissions To Review</h3>
      }
      <ReviewSectionInfoModal
        challengePackage={challengePackage}
        closeHandler={() => { setSubmissionUnderInspection(null) }}
        inspectedSubmission={submissionUnderInspection}
        packageAccesses={packageAccesses}
      />
    </Masonry >
  );
}

export default ReviewSectionGallery;