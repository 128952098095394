import React, { useState, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  Button
} from "reactstrap";
import './SubmitTextAnswerModal.css';
import { ActivePackageContext } from 'contexts/ActivePackageContext/ActivePackageContext';
import { COLOR } from 'constants/color';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import ChallengeFormFieldRow from 'components/Forms/ChallengeForm/ChallengeFormFieldRow';
import { IsEmpty } from 'helpers/Strings';
import { ViewPackageContext } from 'contexts/ViewPackageContext/ViewPackageContext';
import { NotificationContext } from "contexts/NotificationContext/NotificationContext";


const MESSAGES = {
  INCORRECT_MESSAGE: {
    MESSAGE: "Incorrect Answer",
    COLOR: COLOR.ALERT_RED_HEX
  },
  CORRECT_MESSAGE: {
    MESSAGE: "CORRECT!",
    COLOR: COLOR.SUCCESS_GREEN_HEX
  },
  ERROR_MESSAGE: {
    MESSAGE: "There was an error submitting your answer. Please try again.",
    COLOR: COLOR.ALERT_RED_HEX
  }
}

const SubmitTextAnswerModal = ({
  transparent,
  visible,
  headerText,
  closeHandler,
  challenge
}) => {
  const [textAnswer, setTextAnswer] = useState("");
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusColor, setStatusColor] = useState(MESSAGES.INCORRECT_MESSAGE.COLOR);
  const _field = useRef(null);
  const {
    SubmitChallengeAnswer,
    isChallengeBeingSubmitted,
  } = useContext(ActivePackageContext);
  const { notify } = React.useContext(NotificationContext);

  const {
    challengePackage,
    challengePackageAccess,
  } = useContext(ViewPackageContext);

  const closeModalHandler = () => {
    closeHandler();
  }

  React.useEffect(() => {
    setIsIncorrect(false);
    setTextAnswer("");
    setIsSubmitted(false);
  }, [visible])

  React.useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        closeModalHandler()
      }, 3000)
    }
  }, [isSubmitted])

  return (
    <Modal
      centered
      show={visible}
      onHide={closeModalHandler}
      dialogClassName="submitTextAnswerModalRoot"
      aria-labelledby="submit-text-answer-modal"
      fade={true}
      backdropClassName="submitTextAnswerModalOverlay"
    >
      <Modal.Header className={"submitTextAnswerModalBody"}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingTop: 20,
          paddingBottom: 0
        }}>
        <Button
          size={"sm"}
          className={"viewPackageDetailModalCloseButton"}
          onClick={closeModalHandler}>
          <span className="btn-inner--icon">
            <i className="fas fa-times" />
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body className={"submitTextAnswerModalBody"}>
        {
          !isSubmitted ?
            <>
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {challenge?.challenge?.textQuestion}
              </Row>
              <ChallengeFormFieldRow
                isResetDisabled={true}
                fields={[
                  {
                    width: "12",
                    disabled: isChallengeBeingSubmitted(challenge?.challenge) || isSubmitted,
                    defaultValue: undefined,
                    placeholder: "Your Answer",
                    isInvalid: isIncorrect,
                    value: textAnswer,
                    changeHandler: (value) => { setTextAnswer(value); setIsIncorrect(false) },
                    invalidFeedback: statusMessage,
                  }
                ]}
              />
            </> :
            <div style={
              {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 30,
                borderWidth: 3,
                borderRadius: 10,
                borderColor: COLOR.SUCCESS_GREEN_HEX,
                fontWeight: 'bold',
                fontSize: 24,
                color: COLOR.SUCCESS_GREEN_HEX,
                border: "solid",
              }
            }>
              {challenge?.challenge?.allowAnyTextAnswer ? "Accepted!" : "Correct!"}
            </div>
        }
      </Modal.Body>
      <Modal.Footer className={"submitTextAnswerModalFooter"} style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Button
          className="my-2"
          style={{ marginRight: 0, width: "100%" }}
          color="success"
          type="button"
          disabled={IsEmpty(textAnswer) || isChallengeBeingSubmitted(challenge?.challenge) || isSubmitted}
          onClick={() => {
            SubmitChallengeAnswer(
              challengePackage,
              challenge?.challenge,
              challengePackageAccess,
              undefined,
              textAnswer,
              () => {
                challenge?.challenge?.allowAnyTextAnswer ?
                  notify("success", "Accepted!", "Congratulations, your answer was Accepted!")
                  : notify("success", "Correct!", "Congratulations, you submitted the correct answer!")
                setIsSubmitted(true);
              },
              () => {
                setIsIncorrect(true);
                setStatusMessage(MESSAGES.INCORRECT_MESSAGE.MESSAGE)
                setStatusColor(MESSAGES.INCORRECT_MESSAGE.COLOR);
              },
              () => {
                notify("danger", "Submission Failed", "Your answer couldn't be submitted. Please refresh the page and try again or contact support. (ERR: 1001)")
              }
            )
          }}>
          {
            isChallengeBeingSubmitted(challenge?.challenge) ?
              <Spinner /> :
              isSubmitted ?
                <i className='fa fa-check' style={{ color: "white", fontSize: 24 }} /> :
                "Submit Answer"
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubmitTextAnswerModal;