/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import {
  Input,
  Col,
  Button
} from "reactstrap";

const TextAnswerRow = (props) => {
  return (
    <div className="form-row mb-1" style={{ alignItems: 'flex-end' }}>
      <Col className="mb-3" md={props.labelMd ?? "4"}>
        <Input
          defaultValue={props.textAnswer}
          id="textAnswerRowLabel"
          placeholder="Enter a Valid Answer"
          type="text"
          disabled={props.disabled}
          onChange={(e) => {
            props.updateHandler(e.target.value, props.index)
          }}
        />
      </Col>
      <Button
        className="btn-icon-only ml-2 mb-3"
        color="primary"
        type="button"
        disabled={props.disabled}
        style={{ height: 46, width: 46 }}
        onClick={() => { props.deleteRowHandler(props.index) }}
      >
        <span className="btn-inner--icon">
          <i className="fas fa-trash-alt" />
        </span>
      </Button>
    </div>
  );
}

TextAnswerRow.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  index: PropTypes.number,
  deleteRowHandler: PropTypes.func,
  updateHandler: PropTypes.func,
};

export default TextAnswerRow;
