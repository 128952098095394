/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Modal,
  Button,
  Col,
  UncontrolledTooltip,
  Row,
} from "reactstrap";


const TextAnswersModal = ({
  isOpen,
  closeHandler,
  submissions,
  title,
  hideChallengeTitle,
  hideUsername }) => {
  const handleCopy = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (err) {
      console.error("Error copying text: ", err);
    }
  };
  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={isOpen}
      toggle={closeHandler}
      scrollable
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {title + " (" + submissions?.length + (submissions?.length === 1 ? " Submission)" : " Submissions)")}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeHandler}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Col>
          {submissions.map((submission, idx) => {
            return (
              <>
                <button
                  className="btn-icon-clipboard"
                  id={`CopySubmissionTooltip${idx}`}
                  onClick={async () => { await handleCopy(submission?.textAnswer) }}
                  type="button">
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <p>{submission?.textAnswer}</p>
                    {!hideUsername && <b style={{ fontSize: 12 }}>{"Username: " + submission?.userInfo?.username}</b>}
                    {!hideChallengeTitle && <b style={{ fontSize: 12 }}>{"Challenge: " + submission?.challengeInfo?.title}</b>}
                  </div>
                </button>
                <UncontrolledTooltip delay={0} target={`CopySubmissionTooltip${idx}`}>
                  Copy Answer Text
                </UncontrolledTooltip>
              </>
            )
          })}
        </Col>
      </div>
    </Modal>
  );
}

export default TextAnswersModal;
