import { CHALLENGE_PACKAGE_TYPE, CHALLENGE_ENDPOINT, SPEED, LOOK_UP_TYPE, CHALLENGE_ANSWER_STATUS } from "../constants";
import { IsEmpty } from "./Strings";
import GetProtectedAPIClient from '../api/protectedAPIClient';
import { DistanceBetween } from "./Distance";
import { InIFrame } from "./IFrame";

export const VALID_CHARACTERS_STRING = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";

export const GetChallengeById = (challengePackage, challengeId) => {
  const challenges = challengePackage.challenges;
  let selectedChallenge = challenges.filter((challenge) => { return challenge.challengeId === challengeId });

  return selectedChallenge.length > 0 ? selectedChallenge[0] : null;
}

export const GetPackageTypeName = (type) => {
  return type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT ? "Scavenger Hunt" : "Challenge Track";
}

export const IsValidPackageLookUpCode = (lookUpCode) => {
  var isValid = true;
  if (IsEmpty(lookUpCode) || lookUpCode?.length < 5 || lookUpCode?.length > 30) {
    return false;
  }

  lookUpCode?.split("").forEach(
    (letter) => {
      if (isValid && !VALID_CHARACTERS_STRING.includes(letter)) {
        isValid = false;
      }
    }
  )

  return isValid;
}

export function CanBeStarted(challengePackageBundle) {
  if (!challengePackageBundle) return false;
  var hasAccess = HasAccess(challengePackageBundle);

  return hasAccess && IsValidTime(challengePackageBundle.challengePackage);
}

export function HasAccess(challengePackageBundle) {
  var access = challengePackageBundle.challengePackageAccess;
  var challengePackage = challengePackageBundle.challengePackage;
  return (challengePackage && challengePackage.requiresAccessCode === false) || (challengePackage && challengePackage.requiresAccessCode === true && access !== null && !access.invalidated);
}

export function IsValidTime(challengePackage) {
  if (!challengePackage) return false;
  const isBefore = IsBeforeStartTime(challengePackage);
  const isAfter = IsAfterEndTime(challengePackage);
  return !isBefore && !isAfter;
}

export function IsBeforeStartTime(challengePackage, offset = 0) {
  if (!challengePackage) return false;
  const now = new Date();
  const isBefore = challengePackage.startTime !== null && now < new Date(new Date(challengePackage.startTime) - offset);
  return isBefore;
}

export function IsAfterEndTime(challengePackage) {
  if (!challengePackage) return false;
  const now = new Date();
  const isAfter = challengePackage.endTime !== null && now >= new Date(challengePackage.endTime);
  return isAfter;
}

export function GetWalkingDistance(challengePackage) {
  if (!challengePackage) {
    return null
  }
  const challenges = challengePackage?.challenges.filter((challenge)=>{return !challenge?.challenge?.noLocation});

  if (!challenges || challenges.length === 0) {
    return null;
  }
  
  let unit = "mi";
  let dist = 0.0;
  challenges.forEach((challenge, idx) => {
    if (idx !== 0) {
      const prevChallenge = challenges[idx - 1];
      const centerPoint = { latitude: challenge.centerLat, longitude: prevChallenge.centerLong };
      const legA = DistanceBetween(
        { latitude: challenge.centerLat, longitude: challenge.centerLong },
        centerPoint,
      );
      const legB = DistanceBetween(
        centerPoint,
        { latitude: prevChallenge.centerLat, longitude: prevChallenge.centerLong },
      );
      dist += parseFloat(legA) + parseFloat(legB);
    }
  })

  return { distance: dist.toFixed(2), unit: unit };
}

export function GetWalkingDistanceForChallenges(challenges) {
  if (!challenges === null) {
    return null
  }
  let unit = "mi";
  let dist = 0.0;
  challenges.forEach((challenge, idx) => {
    if (idx !== 0) {
      const prevChallenge = challenges[idx - 1];
      const centerPoint = { latitude: challenge.lat, longitude: prevChallenge.long };
      const legA = DistanceBetween(
        { latitude: challenge.lat, longitude: challenge.long },
        centerPoint,
      );
      const legB = DistanceBetween(
        centerPoint,
        { latitude: prevChallenge.lat, longitude: prevChallenge.long },
      );
      dist += parseFloat(legA) + parseFloat(legB);
    }
  })

  return { distance: dist.toFixed(2), unit: unit };
}

export function GetEstimatedDistanceAndTime(challengePackage) {
  if (!challengePackage || challengePackage.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT) {
    return null
  }
  const distanceInMiles = GetWalkingDistance(challengePackage);
  const walkingTime = distanceInMiles.distance / SPEED.WALKING_MPH;

  return { distance: distanceInMiles, time: { hour: Math.floor(walkingTime), minutes: Math.floor((walkingTime * 60) % 60) } }
}

export function GetEstimatedDistanceAndTimeForChallenges(challenges) {
  if (!challenges) {
    return null
  }
  const distanceInMiles = GetWalkingDistanceForChallenges(challenges);
  const walkingTime = distanceInMiles.distance / SPEED.WALKING_MPH;

  return { distance: distanceInMiles, time: { hour: Math.floor(walkingTime), minutes: Math.floor((walkingTime * 60) % 60) } }
}

export const ShouldShowLineOnMap = (challengePackage) => {
  return challengePackage && challengePackage.type !== CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT && !challengePackage.hideLine
}

export async function LookUpPackage(lookUpCode, userId, accessToken, successCallback, errorCallback) {
  const challengeAPIClient = await GetProtectedAPIClient(CHALLENGE_ENDPOINT, accessToken);
  const isIframe = InIFrame();
  //Deprecated, type not used by API
  const type = CHALLENGE_PACKAGE_TYPE.TRACK;
  challengeAPIClient.get('/Package/LookUp?lookUpCode=' + lookUpCode.toUpperCase() + "&userId=" + userId + "&type=" + type + "&lookUpType=" + (isIframe ? LOOK_UP_TYPE.IFRAME : LOOK_UP_TYPE.WEB) + "&getCreatedList=true")
    .then((res) => {
      if (!res.data) {
        errorCallback();
      } else {
        const result = res.data;
        successCallback(result);
      }
    })
    .catch((error) => {
      console.log(error);
      errorCallback(error);
    });
}

export async function UnauthenticatedLookUpPackage(lookUpCode, successCallback, errorCallback) {
  const challengeAPIClient = await GetProtectedAPIClient(CHALLENGE_ENDPOINT, null);
  const isIframe = InIFrame();
  challengeAPIClient.get('/Package/UnauthenticatedLookUp?lookUpCode=' + lookUpCode.toUpperCase() + "&lookUpType=" + (isIframe ? LOOK_UP_TYPE.IFRAME : LOOK_UP_TYPE.WEB) + "&getCreatedList=true")
    .then((res) => {
      if (!res.data) {
        errorCallback();
      } else {
        const result = res.data;
        successCallback(result);
      }
    })
    .catch((error) => {
      console.log(error);
      errorCallback(error);
    });
}

export const IsChallengeSubmittedInPackage = (challenge, challengePackageAccess) => {
  if (!challenge || !challengePackageAccess) return false;
  return !!challengePackageAccess?.submittedChallengeAnswers?.find((sc) => { return sc?.challengeId === challenge?.id })
}

export const IsChallengeSubmittedInPackageAndApproved = (challenge, challengePackageAccess) => {
  if (!challenge || !challengePackageAccess) return false;
  return !!challengePackageAccess?.submittedChallengeAnswers?.find((sc) => { return sc?.challengeId === challenge?.id && sc?.status === CHALLENGE_ANSWER_STATUS.APPROVED })
}

export const AllChallengesInPackageHaveStatus = (challengePackageAccess, status) => {
  if (!challengePackageAccess || challengePackageAccess?.submittedChallengeAnswers?.length === 0) return false;
  let allChallengesInPackageHaveStatus = true;
  challengePackageAccess?.submittedChallengeAnswers?.forEach((sca)=>{
    if (sca?.status !== status) {
      allChallengesInPackageHaveStatus = false;
    }
  })
  return allChallengesInPackageHaveStatus;
}

export const GetSubmissionForChallenge = (challenge, challengePackageAccess) => {
  if (!challenge || !challengePackageAccess) return null;
  return challengePackageAccess?.submittedChallengeAnswers?.find((sc) => { return sc?.challengeId === challenge?.id })
}

export const AllChallengesNoLocation = (challenges) => {
  let allChallengesNoLocation = true;
  challenges.forEach((challenge)=>{
    if (!challenge?.challenge?.noLocation) {
      allChallengesNoLocation = false
    }
  })
  return allChallengesNoLocation;
}