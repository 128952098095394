import React from 'react';
import GoogleApiWrapper from 'components/GoogleMap/ViewPackageMap/ViewPackageMapDetails';
import useWindowDimensions from 'hooks/Dimensions';
import useBreakpoints from 'hooks/Responsive';
import { UpdateChallengePackageContext } from "contexts/UpdateChallengePackageContext/UpdateChallengePackageContext";
import { UserInfoContext } from "contexts/UserInfoContext";

const ViewPackageDetails = (props) => {
  const { height, width } = useWindowDimensions();
  const { isTabletOrMobile } = useBreakpoints();
  const {
    challengePackage,
    removeChallengeHandler,
    hideLine,
    challenges,
    challengePackageType,
    isCreateMode
  } = React.useContext(UpdateChallengePackageContext);
  const { userLocation } = React.useContext(UserInfoContext);
  const [latitude, setLatitude] = React.useState(challengePackage?.packageArea?.coordinate?.latitude ? challengePackage?.packageArea?.coordinate?.latitude : (userLocation?.lat || 51.895081));
  const [longitude, setLongitude] = React.useState(challengePackage?.packageArea?.coordinate?.longitude ? challengePackage?.packageArea?.coordinate?.longitude : (userLocation?.lng || -57.629879));

  return (
    <GoogleApiWrapper
      isCreateMode={isCreateMode}
      isTabletOrMobile={isTabletOrMobile}
      userData={props.userData}
      lat={parseFloat(latitude)}
      lng={parseFloat(longitude)}
      hideLine={hideLine}
      type={challengePackageType}
      challengePackage={challengePackage}
      containerStyle={
        { position: "relative", height: isTabletOrMobile ? height * .6 : height, width: "100%", maxWidth: "100%" }}
      challenges={challenges}
      removeChallengeHandler={removeChallengeHandler}
      setViewMode={props.setViewMode}
    />
  );
}

export default ViewPackageDetails;