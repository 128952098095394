import React, { useState, useEffect, useRef } from 'react';
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Circle,
  Polyline,
} from 'google-maps-react';
import {
  Button
} from "reactstrap";
import '../../GoogleMap/ViewPackageMap/ViewPackageMap.css';
import ViewPackageDetailsSidebar from 'views/pages/viewPackage/ViewPackageDetailsSidebar';
import 'views/pages/viewPackage/ViewPackage.css';
import { COLOR, DISTANCE, CHALLENGE_PACKAGE_TYPE, MAP_STYLE } from '../../../constants';
import { GetIconColorPackage } from '../../../helpers/Category';
import ViewPackageMapInfoWindow from './ViewPackageMapInfoWindow';
import ViewPackageDetailModal from 'views/pages/viewPackage/ViewPackageDetailModal';
import ViewPackageChallengeList from 'views/pages/viewPackage/ViewPackageChallengeList';
import ViewPackageUserInfoRow from 'views/pages/viewPackage/ViewPackageUserInfoRow';
import ShowOnly from 'components/Transitions/ShowOnly';
import ViewPackageImageHeader from 'views/pages/viewPackage/ViewPackageImageHeader';
import ViewPackageDescription from 'views/pages/viewPackage/ViewPackageDescription';
import { computeDestinationPoint } from 'geolib';
import { getMarkerIcon } from 'helpers/Marker';

class MarkersList extends React.Component {
  constructor(props) {
    super(props);
    this.markersRendered = false;
    this.props.challenges.forEach((challenge) => {
      this[challenge.challengeId] = React.createRef();
    })
    this.getMarkers = this.getMarkers.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.hideLine !== nextProps.hideLine) {
      return true;
    }

    if (this.props.type !== nextProps.type) {
      return true;
    }

    if (JSON.stringify(this.props.challenges) === JSON.stringify(nextProps.challenges) && this.markersRendered) {
      return false;
    }
    this.markersRendered = true;
    return true;
  }

  getScavengerHuntMarkers = () => {
    const challengeMarkers = [];
    if (this.props.challenges) {
      this.props.challenges.forEach((challenge, idx) => {
        let colorPackage = GetIconColorPackage(challenge.challengeType ?? 25);
        const centerPosition = {
          lat: challenge.centerLat,
          lng: challenge.centerLong
        };

        let iconMarker = new window.google.maps.MarkerImage(
          require('../../../assets/misc_icons/starPurple.png').default,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          new window.google.maps.Point(15, 15), /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(30, 30)
        );
        const marker =
          <Marker
            ref={this[challenge.challengeId]}
            map={this.props.map}
            optimized
            google={this.props.google}
            key={challenge.challengeId}
            onClick={(props, marker, e) => { this.props.onClick(props, marker, e, challenge) }}
            name={challenge.title}
            position={centerPosition}
            icon={getMarkerIcon()}
            label={
              {
                text: (challenge.order + 1).toString(),
                color: '#ffffff',
                fontSize: "12px",
                className: "markerLabel",
              }}
          >
          </Marker>
        challengeMarkers.push(marker);
      })
    }
    return challengeMarkers;
  };

  getChallengeTrackMarkers = () => {
    const challengeMarkers = [];
    if (this.props.challenges) {
      this.props.challenges.forEach((challenge, idx) => {
        let colorPackage = GetIconColorPackage(challenge.challengeType ?? 25);
        const centerPosition = {
          lat: challenge.centerLat,
          lng: challenge.centerLong
        };

        let iconMarker = new window.google.maps.MarkerImage(
          require('../../../assets/misc_icons/starOrange.png').default,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          new window.google.maps.Point(15, 15), /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(30, 30)
        );

        const marker =
          <Marker
            ref={this[challenge.challengeId]}
            map={this.props.map}
            optimized
            google={this.props.google}
            key={challenge.challengeId + "markers"}
            onClick={(props, marker, e) => { this.props.onClick(props, marker, e, challenge) }}
            name={challenge.title}
            position={centerPosition}
            icon={getMarkerIcon()}
            label={
              {
                text: (challenge.order + 1).toString(),
                color: '#ffffff',
                // fontSize: "12px",
                // className: "markerLabel",
              }}
          />
        challengeMarkers.push(marker);
      })
    }
    if (!this.props.hideLine) {
      challengeMarkers.push(<Polyline
        map={this.props.map}
        google={this.props.google} Î
        onClick={() => { }}
        path={
          this.props.challenges.map(
            (c) => { return { lat: c.centerLat, lng: c.centerLong } })
        }
        options={{ strokeColor: COLOR.ROAMLI_PURPLE_HEX, strokeWeight: 5 }} />);
    }

    return challengeMarkers;
  }

  getMarkers() {
    const challengeMarkers =
      this.props.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT ?
        this.getScavengerHuntMarkers()
        : this.getChallengeTrackMarkers();
    return challengeMarkers
  }

  render() {
    const challengeMarkers = this.getMarkers()

    return (
      <>
        {challengeMarkers}
      </>
    )
  }
}

class ScavengerHuntRadiusMarker extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    const centerPosition = {
      lat: this.props.activeChallenge?.centerLat,
      lng: this.props.activeChallenge?.centerLong
    };

    let colorPackage = GetIconColorPackage(this.props.activeChallenge?.challengeType ?? 25);
    return (
      <>
        {
          this.props.activeChallenge &&
          <Circle
            map={this.props.map}
            google={this.props.google}
            onClick={() => { }}
            center={centerPosition}
            radius={this.props.activeChallenge.radius ?? DISTANCE.SCAVENGER_HUNT_RADIUS}
            strokeColor={colorPackage.color}
            fillColor={colorPackage.backgroundColor} />
        }
      </>
    )
  }
}

const MapContainer = (props) => {
  const bounds = new window.google.maps.LatLngBounds();
  const initialPosition = { lat: props.lat, lng: props.lng };
  
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [activeChallenge, setActiveChallenge] = useState(null);
  const [mapBounds, setMapBounds] = useState(bounds);
  const [zoom, setZoom] = useState(undefined);
  const [center, setCenter] = useState(initialPosition);

  const markersListRef = useRef();
  const mapRef = useRef();

  const eventHasStarted = () => {
    return (
      !props.challengePackage.startTime ||
      (props.challengePackage.startTime &&
        new Date().getTime() >=
          new Date(props.challengePackage.startTime).getTime())
    );
  };

  useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();
    props.challenges.forEach((challenge) => {
      const location = new window.google.maps.LatLng(
        challenge.centerLat,
        challenge.centerLong
      );
      bounds.extend(location);
    });

    if (!eventHasStarted() && props.challengePackage.challenges[0]) {
      const { centerLat: lat, centerLong: lng, radius } =
        props.challengePackage.challenges[0];
      const radPoints = [
        computeDestinationPoint({ latitude: lat, longitude: lng }, radius, 0),
        computeDestinationPoint({ latitude: lat, longitude: lng }, radius, 180),
        computeDestinationPoint({ latitude: lat, longitude: lng }, radius, 90),
        computeDestinationPoint({ latitude: lat, longitude: lng }, radius, 270),
      ];
      radPoints.forEach((point) => {
        bounds.extend(new window.google.maps.LatLng(point.latitude, point.longitude));
      });
    }

    setMapBounds(bounds);
  }, [props.challenges, props.challengePackage]);

  const handleCenterChanged = (map) => {
    if (!map?.center || !props.updateCoordinatesHandler) return;
    props.updateCoordinatesHandler({
      latitude: map.center.lat(),
      longitude: map.center.lng(),
    });
  };

  const onMarkerClick = (markerProps, marker, e, challenge) => {
    setActiveMarker(marker);
    setShowInfoWindow(true);
    setActiveChallenge(challenge);
  };

  const onClose = () => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker(null);
      setActiveChallenge(null);
    }
  };

  const setActiveChallengeFromOutsideOfMap = (challenge) => {
    if (challenge) {
      const marker =
        markersListRef.current[challenge.challengeId]?.current?.marker;
      if (marker) {
        onMarkerClick({}, marker, null, challenge);
      }
    }
  };
  console.log("center", center);
  return (
    <div className="viewPackageRoot">
      <div
        className={`map-container viewPackageMap`}
        style={{
          flexDirection: props.isTabletOrMobile ? 'column' : 'row',
        }}
      >
        <ViewPackageDetailsSidebar
          setViewMode={props.setViewMode}
          onSideMenuClickHandler={setActiveChallengeFromOutsideOfMap}
          updatePackageHandler={props.updatePackageHandler}
          userData={props.userData}
        />
        {!props.isTabletOrMobile && (
          <Map
            ref={mapRef}
            className="googleMap"
            google={props.google}
            containerStyle={props.containerStyle}
            zoom={zoom}
            initialCenter={initialPosition}
            center={center}
            bounds={props.isCreateMode ? undefined : mapBounds}
            fullscreenControl={false}
            streetViewControl={false}
            mapTypeControl={false}
            onClick={onClose}
            onCenterChanged={(t, map, coord) => handleCenterChanged(map)}
            styles={[
              {
                featureType: 'poi.business',
                stylers: [{ visibility: 'off' }],
              },
            ]}
          >
            <MarkersList
              ref={markersListRef}
              google={props.google}
              onClick={onMarkerClick}
              challengePackage={props.challengePackage}
              type={props.type}
              challenges={props.challenges}
              hideLine={props.hideLine}
            />

            {props.type === CHALLENGE_PACKAGE_TYPE.SCAVENGER_HUNT && (
              <ScavengerHuntRadiusMarker
                google={props.google}
                activeChallenge={activeChallenge}
              />
            )}
            {!eventHasStarted() &&
              !activeChallenge &&
              props.challengePackage.challenges[0] && (
                <ScavengerHuntRadiusMarker
                  google={props.google}
                  activeChallenge={props.challengePackage.challenges[0]}
                />
              )}
            <ViewPackageMapInfoWindow
              marker={activeMarker}
              visible={showInfoWindow}
              onClose={onClose}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  minHeight: 200,
                  minWidth: 200,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ height: 150, width: 250, overflow: 'hidden' }}>
                    {activeChallenge?.picChallengeUrl ? (
                      <img
                        src={activeChallenge?.picChallengeUrl}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      <h6 style={{ textAlign: 'center' }}>No Image</h6>
                    )}
                  </div>
                  <h4
                    style={{
                      textAlign: 'left',
                      padding: 10,
                      width: '100%',
                      paddingBottom: 0,
                      marginBottom: 0,
                    }}
                  >
                    {activeChallenge?.title}
                  </h4>
                  {activeChallenge?.challenge?.address?.displayedAddress && (
                    <small
                      style={{
                        textAlign: 'left',
                        paddingLeft: 10,
                        paddingRight: 10,
                        width: '100%',
                      }}
                    >
                      {activeChallenge?.challenge?.address?.displayedAddress}
                    </small>
                  )}
                  <div className="viewPackageInfoWindowButtonContainer">
                    <Button
                      style={{
                        width: '50%',
                        marginBottom: 0,
                        marginTop: 10,
                        marginRight: 0,
                        marginLeft: 0,
                        borderRadius: 0,
                      }}
                      color="primary"
                      type="button"
                      onClick={() => setShowDetailModal(true)}
                    >
                      View
                    </Button>
                    <Button
                      style={{
                        width: '50%',
                        marginBottom: 0,
                        marginTop: 10,
                        marginRight: 0,
                        marginLeft: 0,
                        borderRadius: 0,
                      }}
                      color="danger"
                      type="button"
                      onClick={() =>
                        props.removeChallengeHandler(activeChallenge)
                      }
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            </ViewPackageMapInfoWindow>
          </Map>
        )}
        <ViewPackageDetailModal
          show={showDetailModal}
          showHandler={setShowDetailModal}
          activeChallengeHandler={setActiveChallengeFromOutsideOfMap}
          challenges={props.challenges}
          challenge={activeChallenge}
        />
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBVA1mMwDKj4H4Vr1xtyeGrYDFXrkZfBeo'
})(MapContainer);