/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  UncontrolledTooltip,
  Modal,
  Button,
  Col,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import classnames from "classnames";
import GoogleApiWrapper from '../../../GoogleMap/ChallengePackageMap/ChallengePackageMap';
import ChallengeAddTable from './ChallengeAddTable';
import { useAuth0 } from "@auth0/auth0-react";
import { UserInfoContext } from '../../../../contexts/UserInfoContext';
import GetProtectedAPIClient from '../../../../api/protectedAPIClient';
import { DISTANCE } from "../../../../constants";
import { UpdateChallengePackageContext } from "contexts/UpdateChallengePackageContext/UpdateChallengePackageContext";
import useWindowDimensions from 'hooks/Dimensions';
import useBreakpoints from 'hooks/Responsive';
import ViewPackageDetailModal from "views/pages/viewPackage/ViewPackageDetailModal";

export const CHALLENGES_DISPLAYED = {
  BOTH: 'both',
  PERSONAL: 'personal',
  ROAMLI: 'Roamli'
}

const ChallengeUpdateModal = (props) => {
  const {
    addChallengeHandler,
    removeChallengeHandler,
    addChallengesModalOpen,
    generateChallengePackageChallenge,
    setAddChallengesModalOpen,
    challengePackage,
    challengePackageType,
    challenges: challengePackageChallenges,
    hideLine,
    addChallengePackageChallengesHandler
  } = React.useContext(UpdateChallengePackageContext);
  const { height, width } = useWindowDimensions();
  const { isTabletOrMobile } = useBreakpoints();
  const { isAuthenticated } = useAuth0();
  const { user, challenges, userLocation } = React.useContext(UserInfoContext);
  const [challengesDisplayed, setChallengesDisplayed] = React.useState(CHALLENGES_DISPLAYED.PERSONAL);
  const [officialChallenges, setOfficialChallenges] = React.useState([]);
  const [loadingOfficialChallenges, setLoadingOfficialChallenges] = React.useState(false);
  const [bothChallenges, setBothChallenges] = React.useState(challenges);
  const [mapCoordinates, setMapCoordinates] =
    React.useState(
      {
        latitude: challengePackage?.packageArea?.coordinate?.latitude ?? (userLocation?.lat || 41.895081),
        longitude: challengePackage?.packageArea?.coordinate?.longitude ?? (userLocation?.lng || -87.629879)
      }
    );
  const [challengesToAdd, setChallengesToAdd] = React.useState([]);
  const [showDetailModalChallenge, setShowDetailModalChallenge] = React.useState(null);

  const getOfficialChallenges = async () => {
    if (!isAuthenticated) {
      return;
    }
    setLoadingOfficialChallenges(true);
    try {
      const prodChallenge = "https://roamlidevchallenge.azurewebsites.net/api/Challenge";
      var challengeAPIClient = await GetProtectedAPIClient(prodChallenge, user.accessToken);
      challengeAPIClient.get("?id=" + user.id + "&lat=" + mapCoordinates.latitude + "&long=" + mapCoordinates.longitude + "&rad=" + (DISTANCE.REGION_RADIUS_M))
        .then(newRChals => {
          setOfficialChallenges(newRChals.data);
          let newBothChallenges = [...challenges];
          newRChals.data.forEach(rChal => {
            if (!newBothChallenges.find(x => x.id === rChal.id)) {
              newBothChallenges.push(rChal);
            }
          })
          setBothChallenges(newBothChallenges);
          setLoadingOfficialChallenges(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingOfficialChallenges(false);
        })

    } catch (e) {
      setLoadingOfficialChallenges(false);
      console.log(e.message);
    }
  };

  const getAddChallenges = () => {
    let addChallenges = [];
    switch (challengesDisplayed) {
      case CHALLENGES_DISPLAYED.ROAMLI:
        addChallenges = officialChallenges;
        break;
      case CHALLENGES_DISPLAYED.BOTH:
        addChallenges = bothChallenges;
        break;
      case CHALLENGES_DISPLAYED.PERSONAL:
      default:
        addChallenges = challenges;
        break;
    }
    // return addChallenges.filter((challenge) => { return !challengePackageChallenges.find(x => x.challengeId === challenge.id) });
    return addChallenges;
  }

  const addChallenges = getAddChallenges();

  const addToAddChallengesQueue = (challenge) => {
    const cpc = generateChallengePackageChallenge(challenge, combinedChallenges().length);
    const newChallengesToAdd = [...challengesToAdd];
    newChallengesToAdd.push(cpc);
    setChallengesToAdd(newChallengesToAdd);
  }

  const removeFromAddChallengesQueue = async (challenge) => {
    const cpc = challenge.challengeId ? challenge : generateChallengePackageChallenge(challenge, combinedChallenges().length);
    const newChallengesToAdd = [];
    challengesToAdd.forEach((chal) => {
      if (chal?.challengeId !== cpc?.challengeId) {
        newChallengesToAdd.push(chal);
      }
    })
    setChallengesToAdd(newChallengesToAdd);
    await removeChallengeHandler(cpc);
  }

  const combinedChallenges = React.useCallback(() => {
    return [...challengePackageChallenges, ...challengesToAdd];
  })

  const closeModal = async () => {
    await addChallengePackageChallengesHandler(challengesToAdd);
    setChallengesToAdd([]);
    setAddChallengesModalOpen(false);
  }

  return (
    <Modal
      className="modal-dialog-centered"
      style={{ width: "80%", maxWidth: "80%" }}
      size="xl"
      isOpen={addChallengesModalOpen}
      toggle={closeModal}
      scrollable
    >
      <div className="modal-header" style={{ paddingTop: 10, paddingBottom: 0 }}>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div
        className="modal-body"
        style={
          {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 0,
            paddingBottom: 25
          }
        }>
        <Col xs={12} xl={6} style={{ height: "100%" }}>
          <ChallengeAddTable
            selectedChallenges={combinedChallenges()}
            key={addChallenges.length}
            addChallengeHandler={addToAddChallengesQueue}
            removeChallengeHandler={removeFromAddChallengesQueue}
            challenges={addChallenges}
            loadRoamliChallengesHandler={getOfficialChallenges}
            challengesDisplayed={challengesDisplayed}
            updateChallengesDisplayedHandler={setChallengesDisplayed}
            showDetailModalHandler={(challenge) => {
              const cpc = generateChallengePackageChallenge(challenge, combinedChallenges().length);
              setShowDetailModalChallenge(cpc)
            }}
            loadingOfficialChallenges={loadingOfficialChallenges} />
        </Col>
        {!isTabletOrMobile && <Col xs={6} xl={6}>
          <GoogleApiWrapper
            lat={mapCoordinates.latitude}
            lng={mapCoordinates.longitude}
            hideLine={hideLine}
            type={challengePackageType}
            challengePackage={challengePackage}
            optionChallenges={addChallenges}
            containerStyle={
              {
                position: "relative",
                height: isTabletOrMobile ? height : "calc(100vh - 9rem)",
                width: "100%",
                maxWidth: "100%"
              }
            }
            showDetailModalHandler={(challenge) => {
              const cpc = generateChallengePackageChallenge(challenge, combinedChallenges().length);
              setShowDetailModalChallenge(cpc)
            }}
            challenges={combinedChallenges()}
            updateCoordinatesHandler={setMapCoordinates}
            addChallengeHandler={addToAddChallengesQueue}
            removeChallengeHandler={async (challenge) => {
              await removeFromAddChallengesQueue(challenge);
            }} />
        </Col>}
      </div>
      <ViewPackageDetailModal
        show={showDetailModalChallenge}
        showHandler={(value) => {
          setShowDetailModalChallenge(null);
        }}
        challenges={[]}
        challenge={showDetailModalChallenge} />
    </Modal>
  );
}

export default ChallengeUpdateModal;
