/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  UncontrolledTooltip,
} from "reactstrap";
import { GetFilterNameFromNumber } from '../../../helpers/Filters';
import { SanitizeInternalString } from '../../../helpers/Strings';
import MasonryModal from '../../Modals/MasonryModal/MasonryModal';
import Avatar from "../../../components/Images/RoundedImages/Avatar";
import { CHALLENGE_ANSWER_STATUS } from "../../../constants";
import TextAnswersModal from "components/Modals/MasonryModal/TextAnswersModal";

const ParticipantTableRow = (props) => {
  const [imageModalOpen, setImageModalOpen] = React.useState(false);
  const [textModalOpen, setTextModalOpen] = React.useState(false);
  const formatDate = (timestamp) => {
    if (timestamp === null) {
      return null;
    }
    let date = new Date(timestamp);
    return "" + (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + date.getHours() + ":" + formatTime(date.getMinutes()) + ":" + formatTime(date.getSeconds());
  }

  const formatTime = (time) => {
    if (time.toString().length === 1) {
      return "0" + time;
    }
    return time.toString();
  }

  const getSubmissionUrls = () => {
    const imageSubmissions = [];
    props.totalSubmissions.forEach(
      (submission) => {
        if (submission.textAnswer === null || submission.textAnswer === undefined) {
          imageSubmissions.push({ url: submission.picUrl, hoverText: submission.challengeInfo?.title });
        }
      }
    );

    return imageSubmissions;
  }

  const getTextSubmissions = () => {
    const textSubmissions = [];
    props.totalSubmissions.forEach(
      (submission) => {
        if (submission.textAnswer !== null && submission.textAnswer !== undefined) {
          textSubmissions.push(submission);
        }
      }
    );

    return textSubmissions;
  }

  const urls = getSubmissionUrls();
  const textSubmissions = getTextSubmissions();

  return (
    <tr>
      <td className="table-user" style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar imageUrl={props.image} />
        <b className="title">{props.username}</b>
      </td>
      <td>
        <span className="name text-muted">
          {props.name}
        </span>
      </td>
      <td>
        <span className="points text-muted">
          {props.points}
        </span>
      </td>
      <td>
        <span className="submissions text-muted">
          {props.totalSubmissions.length}
        </span>
      </td>
      <td>
        <span className="lastSubmission text-muted">
          {props.totalSubmissions.filter(
            (sca) => { return sca.status === CHALLENGE_ANSWER_STATUS.APPROVED }
          ).length > 0
            ? formatDate(
              props.totalSubmissions[props.totalSubmissions.filter(
                (sca) => { return sca.status === CHALLENGE_ANSWER_STATUS.APPROVED }
              ).length - 1].submissionDate
            )
            : "No Approved Submissions"}
        </span>
      </td>
      <td>
        <span className="approved text-muted">
          {props.approved}
        </span>
      </td>
      <td>
        <span className="inReview text-muted">
          {props.inReview}
        </span>
      </td>
      <td>
        <span className="incorrect text-muted">
          {props.incorrect}
        </span>
      </td>
      <td>
        <span className="devices text-muted">
          {props.devices}
        </span>
      </td>
      <td className="table-actions">
        <a
          className="table-action table-action-delete"
          id="tooltip124750202"
          onClick={() => { setImageModalOpen(true) }}
        >
          <i className="fas fa-camera" />
        </a>
        <UncontrolledTooltip delay={0} target="tooltip124750202">
          View Photo Submissions
        </UncontrolledTooltip>
        <>
          <a
            className="table-action table-action-delete"
            id="textSubmissionTooltip"
            onClick={() => { setTextModalOpen(true) }}
          >
            <i className="fas fa-edit" />
          </a>
          <UncontrolledTooltip delay={0} target="textSubmissionTooltip">
            View Text Submissions
          </UncontrolledTooltip>
        </>
      </td>
      <MasonryModal
        isOpen={imageModalOpen}
        closeHandler={() => { setImageModalOpen(false) }}
        urls={urls}
        title={props.username + "'s Photo Submissions"} />
      <TextAnswersModal
        isOpen={textModalOpen}
        closeHandler={() => { setTextModalOpen(false) }}
        submissions={textSubmissions}
        title={props.username + "'s Text Submissions"}
        hideUsername />
    </tr>
  );
}

export default ParticipantTableRow;
