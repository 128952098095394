import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

const ReviewSectionChallengeSelect = ({
  disabled,
  selectedChallenge,
  setSelectedChallengeHandler,
  challengeSubmissions,
}) => {
  const getDropdownItems = () => {
    let dropdownItems = [
      <DropdownItem
        onClick={
          () => {
            setSelectedChallengeHandler(null)
          }
        }>
        {`All Reviewable Submissions (${Object.values(challengeSubmissions).flat()?.length})`}
      </DropdownItem>
    ];
    Object.values(challengeSubmissions)
      .sort((a, b) => { return a[0].challenge.order < b[0].challenge.order ? -1 : 1 })
      .forEach((submissionsForChallenge) => {
        if (submissionsForChallenge.length === 0) {
          return;
        }
        const challenge = submissionsForChallenge[0].challenge;
        dropdownItems.push(
          <DropdownItem
            onClick={
              () => {
                setSelectedChallengeHandler(challenge)
              }
            }>
            {`#${challenge.order + 1}: ${challenge.title} (${submissionsForChallenge?.length})`}
          </DropdownItem>
        )
      })
    return dropdownItems;
  }

  return (
    <UncontrolledButtonDropdown
      disabled={disabled}>
      <DropdownToggle caret color={disabled ? "secondary" : "primary"}>
        {
          selectedChallenge === null ?
            `All Reviewable Submissions (${Object.values(challengeSubmissions).flat()?.length})` :
            `#${challengeSubmissions[selectedChallenge.challengeId]?.length > 0 && challengeSubmissions[selectedChallenge.challengeId][0]?.challenge?.order + 1}: ${selectedChallenge?.title} (${challengeSubmissions[selectedChallenge.challengeId]?.length})`}
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: (data) => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  overflow: 'auto',
                  maxHeight: 300,
                },
              };
            },
          },
        }}
      >
        {getDropdownItems()}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}

export default ReviewSectionChallengeSelect;